<template>
    <div class="empty">
        <img class="empty_IMG" :src="imgUrl" alt="">
        <p class="empty_msg">{{ EmptyMsg }}</p>
    </div>
</template>

<script>
export default {
    props: {
        // 警示语
        EmptyMsg: {
            type: String,
            default: '暂无任务'
        }
    },

    data () {
        return {
            //  空数据图片
            imgUrl: require('@/assets/uncode/empty.png')
        }
    }
}
</script>

<style lang="scss" scoped>
.empty {
    padding-top: 300px;
    text-align: center;

    .empty_IMG {
        display: block;
        margin: 0 auto;
        width: 474px;
    }

    .empty_msg {
        line-height: 90px;
        font-size: 28px;
        font-weight: bold;
        color: #bdbdbd;
    }
}
</style>
