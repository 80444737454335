<!-- 自定义 loading -->
<template>
  <div class="loading" v-show="isLoading">
    <el-main>
      <div class="shade"></div>
      <div class="loading_gif">
        <img src="@/assets/loading.gif" alt="">
        <div class="loading_text">{{ text }}</div>
      </div>
    </el-main>
  </div>
</template>

<script>
export default {
  props: {
    // 是否显示
    isLoading: {
      type: Boolean,
      default: false
    },
    // 背景颜色
    background: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    // 文字
    text: {
      type: String,
      default: '加载中...'
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-main {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 11;

  .shade {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .loading_gif {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;

    img {
      display: inline-block;
      width: 30%;
    }

    .loading_text {
      margin-top: -60px;
      line-height: 80px;
      font-size: 28px;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
