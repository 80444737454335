<!-- 个人中心 => 合同 => 卡片 -->
<template>
    <div class="pact_code">
        <div class="code_item">
            <!-- 头部 -->
            <div class="code_item_name">
                <span class="title before_label">{{ computedTime(oItem.updatetime, 'yyyy-MM-dd') }}</span>
                <div :class="['code_item_name_type', status_type[oItem.status]?.class]">
                    {{ status_type[oItem.status]?.title }}
                </div>
            </div>
            <!-- 详情区 -->
            <div class="code_item_info">
                <div v-for="(obj, i) in oPactInfos" :key="i" >
                    <span class="label">{{ obj.label }}: </span>
                    <span>{{ oItem[obj.modal] || '无' }}</span>
                </div>
                <div
                    v-for="(name, index) in oItem.mobile_model.split('/')"
                    class="phone"
                    :key="index"
                >
                    <span class="label">{{ `终端名称${ index + 1 }` }}: </span>
                    <span>{{ name || '无' }}</span>
                </div>
            </div>
            <!-- 底部 -->
            <div class="card_fooder">
                <el-button v-if="oItem.status == 5" @click="evSign()" :loading="isLick">开始签署</el-button>
                <a
                    v-if="contractFilter.indexOf(oItem.status) !== -1"
                    class="contract"
                    target="_blank"
                    :href="oItem.agreement_url"
                >
                    查看合同
                </a>
                <!-- <el-button v-else-if="oItem.status == 7" @click="evConfirm()">确认收货</el-button> -->
                <a class="contract" target="_blank" v-if="isShowStatus" :href="oItem.jumpUrl">支付</a>
            </div>
        </div>
        <!-- loading -->
        <Loading :isLoading="loading" :text="loadingText" />
        <!-- 补差款弹窗 -->
        <!-- <pay-methoe-box v-show="isShowPayment" :mobile="oItem.business_mobile" :r_name="oItem.real_name" @evIsShowShade="evPaymentMethoe()" /> -->
    </div>
</template>

<script>
// 接口
import { PostSignQuery, PostConfirm } from '@/api'
// 方法
import { formatDate } from '@/utils/get-page-title.js'
// 组件
import Loading from '@/components/Loading'

export default {
    props: {
        oItem: {
            type: Object
        }
    },
    data () {
        return {
            // 省份
            province: localStorage.getItem('province'),
            // loading
            loading: false,
            loadingText: '合同生成中...',
            // 签署合同锁
            isLick: false,
            // 是否展示补差款弹窗
            isShowPayment: false,
            // 合同查看筛选
            contractFilter: [6, 7, 8],
            // label
            oPactInfos: [
                { label: '姓名', modal: 'real_name' },
                { label: '业务号码', modal: 'business_mobile' },
                { label: '办理套餐', modal: 'meal' },
                { label: '联系电话', modal: 'mobile' },
                { label: '期数', modal: 'net_age' },
                { label: '电子券', modal: 'repay_money' }
                // { label: '补差款', modal: 'difference_price' }
            ],
            // 状态
            status_type: {
                '6': { title: '已签署', class: 'pass' },
                '8': { title: '已收货', class: 'pass' },
                '13': { title: '终端待确认', class: 'await_pass' },
                '15': { title: '合同生成中', class: 'await_pass' }
            }
        }
    },
    methods: {
        // 签约合同(接口)
        apiSignQuery: function () {
            PostSignQuery({ 'white_id': this.oItem.id, backUrl: `http://${location.host}/tips` })
                .then(({ code, data, msg }) => {
                    if (code === 1000) {
                        // console.log('[签署合同]', data)
                        this.loadingText = '合同生成中...'
                        location.href = data.contractUrl
                        return
                    }
                    setTimeout(() => {
                        Object.assign(this, {
                            'loading': false,
                            'isLick': false
                        })
                        this.$message.error(msg)
                    }, 1000)
                })
                .catch(err => {
                    Object.assign(this, {
                        'loading': false,
                        'isLick': false
                    })
                    console.log(err)
                })
        },
        // 确认收货(接口)
        apiConfirm: function () {
            PostConfirm({ 'order_id': this.oItem.id })
                .then(res => {
                    const { code, msg } = res
                    if (code === 1000) {
                        location.reload()
                    } else {
                        this.$message.error(msg)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        // 确认收货(按钮)
        evConfirm: function () {
            this.$confirm('是否确认收货', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.apiConfirm()
                })
        },
        // 补差款方式 (按钮)
        evPaymentMethoe: function (data) {
            // PostWeChatPay({ white_id: this.oItem.id })
            //     .then(({ code, msg, data }) => {
            //         if (code === 1000) {
            //             // console.log('[支付参数]', data)
            //             this.callpay(data)
            //             return
            //         }
            //         this.$message.error(msg)
            //     })
        },
        callpay (data) {
            if (typeof WeixinJSBridge === 'undefined') {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.wxPay, false)
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.wxPay)
                    document.attachEvent('onWeixinJSBridgeReady', this.wxPay)
                }
            } else {
                this.wxPay(data)
            }
        },
        // 支付代码
        wxPay (data) {
            const that = this
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                {
                    'debug': false,
                    'appId': data.appId, // 公众号名称, 由商户传入
                    'timeStamp': data.timeStamp, // 时间戳, 自1970年以来的秒数
                    'nonceStr': data.nonceStr, // 随机串
                    'package': data.package,
                    'signType': data.signType, // 微信签名方式
                    'paySign': data.paySign // 微信签名
                },
                function (res) {
                    if (res.err_msg === 'get_brand_wcpay_request:ok') {
                        // alert('支付成功')
                        that.$router.go(-1)
                    } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
                        // alert('支付取消')
                    } else {
                        // alert('支付失败')
                    }
                }
            )
        },
        // 开始签约
        evSign: function () {
            this.$confirm('是否签约合同', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    Object.assign(this, {
                        'loadingText': '合同确认中...',
                        'loading': true,
                        'isLick': true
                    })
                    // 签约合同 (方法)
                    this.apiSignQuery()
                })
        }
    },
    computed: {
        // 时间格式转换
        computedTime () {
            return function (time, fmt) {
                return formatDate(time, fmt)
            }
        },
        // 状态 title
        oStatusTitle () {
            return function (status) {
                const oGreen = [6, 8]
                return oGreen.indexOf(status) !== -1 ? 'pass' : ''
            }
        },
        // 支付按钮展示状态
        isShowStatus () {
            let is_s = false
            switch (this.province) {
                case '1168':
                    const { referenceCode } = this.oItem
                    if (referenceCode) is_s = true
                    break
            }
            return is_s
        }
    },
    components: {
        Loading
    }
}
</script>

<style lang="scss" scoped>
$fontSize: 26px;

.pact_code {
    margin: 0 auto;
    width: 670px;
    line-height: 66px;
    font-size: 24px;
    border-radius: 6px;

    .code_item {
        padding: 14px 22px 26px;
        margin-bottom: 22px;
        font-size: $fontSize;
        border-radius: 12px;
        box-shadow: 0 0 20px rgba(87, 95, 102, 0.16);

        &_name {
            display: flex;
            justify-content: space-between;
            line-height: 64px;
            border-bottom: 2px solid #EBEBEB;

            .title{
                font-size: 30px;
                color: #333;
            }

            &_type {
                margin-top: 10px;
                padding: 0 20px;
                // width: 120px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                border-radius: 30px;

                &.pass {
                    color: #01B272;
                    background: #E7FFF6;
                }

                &.not_pass {
                    color: #FF693F;
                    background: #FFE8E2;
                }

                &.await_pass {
                    color: #479FF2;
                    background: #E1F0FF;
                }
            }
        }

        &_info {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 24px 24px 0;

            div {
                width: 280px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.phone {
                    width: 100%;
                }

                span {
                    line-height: 44px;
                    color: #666;
                    font-weight: 500;
                }

                .label {
                    color: #BDBDBD;
                }
            }
        }

        // 底部
        .card_fooder {
            text-align: right;

            span, .el-button, .contract {
                display: inline-block;
                padding: 0 14px;
                width: 170px;
                line-height: 48px;
                color: #fff;
                font-weight: 500;
                border-radius: 100px;
                text-align: center;
                background: linear-gradient(90deg, #F2970C, #FEC56E );
            }

            .el-button, .contract {
                margin-right: 20px;
                min-height: 48px;
                border: none;
            }
        }
    }
}
</style>
