<template>
    <!-- 个人信息 -->
    <div class="user_info">
        <div class="user_info_avatar">
            <img :src="oAvatar || oDefAvatar" alt="头像">
        </div>
        <p class="user_info_welcome">
            昵称: {{ name }}
            <text class="phone">(ID:{{ oId || '空' }})</text>
        </p>
    </div>
</template>

<script>
export default {
    data () {
        return {
            // id
            oId: '',
            // 昵称
            name: '',
            // 头像
            oAvatar: '',
            // 默认头像
            oDefAvatar: require('@/assets/Personal/khjl-tx@2x.png')
        }
    },
    mounted () {
        const { real_name: name, avatar, id } = this.$store.getters.info
        Object.assign(this, {
            'oAvatar': avatar,
            'oId': id,
            name
        })
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/user.scss';
.user_info {
    &_welcome {
        color: #333;
        font-size: 28px;
        .phone {
            color: #666;
            font-size: 24px;
        }
    }
}
</style>
