<template>
    <div class="cus_pact">
        <div class="pact_user_header" v-if="isLock">
            <user-header />
        </div>
        <!-- 办理进度、终端选择未配置 卡片 -->
        <section>
            <div
                v-for="(item, index) in oOrders"
                :key="index"
            >
                <!-- 进度卡片 -->
                <schedule-code
                    v-if="scheduleFilter.indexOf(item.status) !== -1"
                    :oItemInfo="item"
                />
                <!-- 合同卡片 -->
                <pact-code
                    v-else
                    :oItem="item"
                />
            </div>
        </section>
        <!-- 无数据展示 -->
        <section v-if="oOrders.length === 0 && !loading">
            <empty />
        </section>
    </div>
</template>

<script>
// 接口
import { GetAgreementList } from '@/api'
// 组件
import ScheduleCode from './components/ScheduleCode.vue'
import UserHeader from '../Main/components/user.vue'
import PactCode from './components/PactCode.vue'
import Empty from '@/views/empty.vue'

export default {
    data () {
        return {
            isLock: false,
            // loading/锁
            loading: true,
            // 订单列表
            oOrders: [],
            // 进度列表筛选
            scheduleFilter: [2, 3, 4, 14]
        }
    },
    methods: {
        /**
         * 获取客户订单列表(接口)
         */
        apiAgreementList: function () {
            const { status } = this.$route.query
            this.isLock = status === '2,3,4,5,6,7,8,14'
            GetAgreementList({ status })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        // data.list[0].status = 5
                        this.oOrders = data.list
                        return
                    }
                    this.$message.error(msg)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    beforeMount () {
        // 获取客户订单列表(方法)
        this.apiAgreementList()
    },
    components: {
        ScheduleCode,
        UserHeader,
        PactCode,
        Empty
    }
}
</script>

<style lang="scss" scoped>
.cus_pact {
    padding: 34px 0;
    .pact_user_header {
        margin-top: -34px;
        padding: 34px 0 0;
        background: #FEC56E;
    }
}
</style>

<style lang="scss">
.el-message-box {
    width: 600px;
}
</style>
